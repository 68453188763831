<template>
  <div></div>
</template>
<script>
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'checkLogin',
  setup() {
    const store = useStore()
    const router = useRouter()
    const params = store.state.userInformation
    const userInfo = params || JSON.parse(sessionStorage.getItem('userInfo'))
    store.dispatch('checkLogin', userInfo).then(res => {
      if (res.data.isLogin) {
        store.commit('setMemberCenter', res.data.memberCenter)
        router.push({ name: 'memberCenter' })
      } else {
        store.commit('setMemberLogin', res.data.memberLogin)
        router.push({ name: 'privacy' })
      }
    }).catch(err => {
      store.commit('showErrorMessage', err.response.data.message)
      router.push({ name: 'errorPage' })
    })
  }
})
</script>
