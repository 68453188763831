<template>
  <div v-if="isLoading" class="loading-block">
    <div class="spinner">
      <font-awesome-icon class="icon" icon="spinner"/>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  setup() {
    const store = useStore()
    const isLoading = computed(() => {
      return store.state.isLoading
    })
    return {
      isLoading
    }
  }
})
</script>
<style lang="scss" scoped>
.loading-block {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  .spinner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .icon {
      animation: loading 2s linear infinite;
      font-size: 30px;
      margin-bottom: 10px;
    }
  }
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
