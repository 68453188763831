<template>
  <loading></loading>
  <router-view v-if="isInit"/>
</template>
<script>
import { defineComponent, ref, onBeforeMount } from 'vue'
import axios from './axios/index'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import loading from './components/loading.vue'
export default defineComponent({
  components: {
    loading
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const isInit = ref(false)
    const init = async() => {
      isInit.value = false
      store.commit('holdLoading', true)
      let params
      const hash = window.location.hash
      if (hash) {
        const paramsArr = hash.split('#', hash.length)[1].split('&')
        for (let i = 0; i < paramsArr.length; i++) {
          const param = paramsArr[i].split('=')
          params = Object.assign({}, params, {
            [param[0]]: param[1]
          })
        }
      } else {
        params = Object.fromEntries([...new URL(window.location.href).searchParams])
      }
      let userToken, linebotGuid
      if (params.userToken) {
        userToken = params.userToken
      } else if (params.userId) {
        userToken = params.userId
      }
      if (params.linebotGuid) {
        linebotGuid = params.linebotGuid
      } else if (params.lineId) {
        linebotGuid = params.lineId
      }
      const getToken = () => {
        return axios({
          method: 'post',
          url: `/api/LINE/${linebotGuid}/MemberCenter/token`,
          params: { userToken }
        })
      }
      const userInfo = { linebotGuid, token: null }
      // 網址有token就拿token 如果是 userToken 就打api換 token
      if (userToken) {
        const { data } = await getToken()
        userInfo.token = data.token
      } else {
        userInfo.token = params.token
      }
      store.commit('SetUserInformation', userInfo)
      sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
      store.commit('holdLoading', false)
      isInit.value = true
      router.push({ name: 'checkLogin' })
    }
    onBeforeMount(() => init())
    return {
      isInit
    }
  }
})
</script>
<style lang='scss'>
@import './assets/scss/all.scss'
</style>
