<template>
  <customer-dialog
    :loginStyle="style"
    :dialogVisible="dialogVisible"
    @nextStep="dialogVisible=false"
    :message="errorMsg"
  ></customer-dialog>
  <div class="main" :style="{backgroundColor:style.backgroundColor}">
    <div class="title" :style="{color:style.titleColor}">註冊/登入會員</div>
    <el-form :model="dynamicValidateForm">
      <el-form-item
        prop="phone"
        class="input"
        :rules="[
      { required: true, message: '電話為必填欄位', trigger: 'blur' },
      { required: true, pattern: /^09[0-9]{8}$/, message: '請輸入正確的電話號碼', trigger: 'blur' }
                ]"
      >
        <el-input
          placeholder="請輸入手機號碼 例：0912345678"
          class="input"
          type="tel"
          v-model="dynamicValidateForm.phone"
        ></el-input>
      </el-form-item>
    </el-form>
    <p class="remind" :style="{color:style.textColor}">＊請確實填寫，登入後無法隨意更換手機號碼</p>
    <div class="comfirm">
      <span :style="{color:style.textColor}">隱私權政策</span>
      <div class="input-group">
        <input v-model="checked" type="checkbox"/>
        <label :style="{color:style.textColor}" for="checked">我同意</label>
      </div>
    </div>
    <div class="text-wrap">
      <span>{{ privacyPolicy }}</span>
    </div>
    <div v-if="!checked || !isPass" class="disable">
      <span>下一步</span>
    </div>
    <div
      v-else
      :style="{color:style.buttons.textColor,backgroundColor: style.buttons.backgroundColor}"
      class="next-step"
      @click="nextStep"
    >
      <span>下一步</span>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed, ref, reactive } from 'vue'
import customerDialog from '../components/customerDialog'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import axios from '../axios/index'

export default defineComponent({
  components: {
    customerDialog
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const memberLogin = computed(() => {
      return store.state.memberLogin
    })
    const parmas = store.state.userInformation
    const userInfo = parmas || JSON.parse(sessionStorage.getItem('userInfo'))
    const dynamicValidateForm = reactive({ phone: '', token: userInfo.token })
    const mobileReg = /^09[0-9]{8}$/
    const errorMsg = ref('出現錯誤請稍後再試')
    const checked = ref(false)
    const isPass = computed(() => {
      return mobileReg.test(dynamicValidateForm.phone)
    })
    const { style, privacyPolicy } = memberLogin.value
    const dialogVisible = ref(false)
    const nextStep = () => {
      if (isPass.value) {
        axios({
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          url: `/api/LINE/${userInfo.linebotGuid}/MemberCenter/sendOTP`,
          data: dynamicValidateForm
        }).then(res => {
          store.commit('setUserPhone', dynamicValidateForm.phone)
          router.push({ name: 'verifyOtp' })
        }).catch((error) => {
          // 印出error message
          // axios 攔截器err要回 promise.reject才會進入catch!!!!!
          // console.log(error.response.data)
          errorMsg.value = error.response.data.message
          dialogVisible.value = true
        })
      }
    }
    return {
      checked,
      dialogVisible,
      nextStep,
      dynamicValidateForm,
      style,
      isPass,
      privacyPolicy,
      errorMsg
    }
  }
})
</script>
<style lang="scss" scoped>
.error-message {
  color: red;
}
.main {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input-wrap {
  margin: 30px auto;
  .phone-number {
    text-indent: 10px;
    height: 45px;
    width: 300px;
  }
}
.remind {
  width: 80%;
  color: #ed5504;
  margin: 10px 0;
}
.comfirm {
  width: 80%;
  display: flex;
  color: #ed5504;
  .input-group {
    margin: 0px 20px;
  }
}
.title {
  color: #3a6ba5;
  padding-top: 50px;
  padding-bottom: 30px;
  font-size: 30px;
  font-weight: 400;
  font-family: "PingFangTC-Regular", "PingFang TC", sans-serif;
}
.next-step {
  width: 250px;
  margin: 20px auto;
  height: 60px;
  background-color: rgba(249, 159, 0, 1);
  border-radius: 50px;
  text-align: center;
  line-height: 60px;
  color: white;
  font-weight: bold;
}
.text-wrap {
  width: 80%;
  border-radius: 5px;
  height: 250px;
  padding: 10px;
  margin: 20px auto;
  background-color: #fff;
  border-radius: 5px;
  white-space: pre-wrap;
  overflow-y: scroll;
}
.warning {
  font-size: 40px;
  color: #333333;
  margin-bottom: 15px;
}
.disable {
  width: 250px;
  margin: 20px auto;
  height: 60px;
  border-radius: 50px;
  text-align: center;
  line-height: 60px;
  color: white;
  font-weight: bold;
  background-color: #ccc;
}
.dialog-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  img {
    width: 50px;
    margin-bottom: 20px;
  }
}
.close {
  margin: 15px auto;
  width: 150px;
  height: 39px;
  background-color: rgba(249, 159, 0, 1);
  border-radius: 50px;
  text-align: center;
  line-height: 39px;
  font-size: 14px;
  color: white;
}
.el-form {
  width: 80%;
}
.el-form-item::v-deep(.el-input__inner) {
  width: 100%;
  margin-top: 20px;
}
</style>
