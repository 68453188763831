<template>
  <customer-dialog
    :loginStyle="style"
    :dialogVisible="dialogVisible"
    @nextStep="dialogVisible=false"
    :message="resMsg"
  ></customer-dialog>
  <customer-dialog
    :loginStyle="style"
    :dialogVisible="successDialogVisible"
    @nextStep="dialogNext"
    :message="resMsg"
  >
    <template #icon>
      <font-awesome-icon class="success" icon="check-circle"/>
    </template>
  </customer-dialog>
  <div
    class="main"
    :style="{backgroundColor:style.backgroundColor}"
  >
    <div class="row">
      <div
        class="title"
        :style="{color:style.titleColor}"
      >簡訊驗證</div>
      <div class="remind">
        <p :style="{color:style.textColor}">已傳送簡訊至{{ phone }}</p>
        <p :style="{color:style.textColor}">請輸入6位驗證碼</p>
      </div>
      <input
        @input="otpValidate"
        v-model="validate"
        maxlength="6"
        class="ver-input"
        type="text"
        placeholder="請輸入驗證碼"
      />
      <div class="remind">
        <p :style="{color:style.textColor}">＊請耐心等候簡訊，不要關閉視窗。</p>
        <p :style="{color:style.textColor}">＊在有效時間倒數結束後，才能按重發驗證碼</p>
      </div>
      <div class="timing">
        <p :style="{color:style.textColor}">有效時間倒數</p>
        <div class="count">
          <span class="time-text" :style="{color:style.textColor}">{{ timesetinterval.min }}</span>
          <span :style="{color:style.textColor}">分</span>
          <span class="time-text" :style="{color:style.textColor}">{{ timesetinterval.sec }}</span>
          <span :style="{color:style.textColor}">秒</span>
        </div>
      </div>
      <div class="button-group">
        <div
          v-if="timeCount!==0"
          class="disable"
        >重發驗證碼</div>
        <div
          v-else
          class="next-step"
          :style="{color:style.buttons.textColor,backgroundColor: style.buttons.backgroundColor}"
          @click="sendOtp"
        >重發驗證碼
        </div>
        <div v-if="!pass" class="disable">下一步</div>
        <div
          v-else
          class="next-step"
          :style="{color:style.buttons.textColor,backgroundColor: style.buttons.backgroundColor}"
          @click="nextStep"
        >下一步
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, computed, onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import customerDialog from '../components/customerDialog'
import { useStore } from 'vuex'
import axios from '../axios/index'

export default defineComponent({
  components: {
    customerDialog
  },
  setup() {
    const store = useStore()
    const timeCount = ref(300)
    const dialogVisible = ref(false)
    const successDialogVisible = ref(false)
    const memberLogin = computed(() => {
      return store.state.memberLogin
    })
    const { style } = memberLogin.value
    const params = store.state.userInformation
    const userInfo = params || JSON.parse(sessionStorage.getItem('userInfo'))
    const router = useRouter()
    const target = ref('')
    const phone = computed(() => {
      return store.state.userPhone
    })
    const resMsg = ref('')
    const dynamicValidateForm = reactive({ phone: store.state.userPhone, token: userInfo.token })
    const pass = ref(false)
    const validate = ref()
    const sendOtp = () => {
      if (timeCount.value === 0) {
        axios({
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          url: `/api/LINE/${userInfo.linebotGuid}/MemberCenter/sendOTP`,
          data: dynamicValidateForm
        }).then(res => {
          timeCount.value = 300
          dialogVisible.value = true
          resMsg.value = res.data.message
        }).catch(err => {
          dialogVisible.value = true
          resMsg.value = err.response.data.message
        })
      }
    }
    const countdown = () => {
      if (timeCount.value > 0) {
        timeCount.value--
      }
    }
    const timesetinterval = computed(() => {
      const time = {
        min: 0,
        sec: 0
      }
      time.min = Math.floor(timeCount.value / 60)
      time.sec = timeCount.value % 60
      return time
    })
    const dialogNext = () => {
      router.push({ name: `${target.value}` })
    }
    const nextStep = () => {
      if (pass.value) {
        axios({
          url: `/api/LINE/${userInfo.linebotGuid}/MemberCenter/verifyOTP`,
          method: 'POST',
          data: {
            token: userInfo.token,
            phone: phone.value,
            otp: validate.value
          }
        }).then(res => {
          if (res.data.data.isRegistered) {
            store.commit('setMemberCenter', res.data.data.memberCenter)
            successDialogVisible.value = true
            resMsg.value = '驗證成功'
            target.value = 'memberCenter'
            // router.push({ name: 'memberCenter' })
          } else {
            store.commit('setMemberRegister', res.data.data.memberRegister)
            successDialogVisible.value = true
            resMsg.value = '驗證成功'
            target.value = 'registered'
            // router.push({ name: 'registered' })
          }
        }).catch(err => {
          if (err.response.data.message === '驗證碼過期') {
            timeCount.value = 300
          }
          dialogVisible.value = true
          resMsg.value = err.response.data.message
        })
      }
    }
    const otpValidate = () => {
      if (validate.value.length === 6) {
        pass.value = true
      } else {
        pass.value = false
      }
    }
    onMounted(() => {
      setInterval(() => { countdown() }, 1000)
    })
    return {
      timeCount,
      countdown,
      timesetinterval,
      sendOtp,
      dialogNext,
      nextStep,
      dialogVisible,
      validate,
      otpValidate,
      successDialogVisible,
      pass,
      phone,
      resMsg,
      style,
      target
    }
  }
})
</script>
<style lang="scss" scoped>
// dialog
.dialog-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  .warning {
    font-size: 40px;
  }
}
.close {
  margin: 15px auto;
  width: 150px;
  height: 39px;
  background-color: rgba(249, 159, 0, 1);
  border-radius: 50px;
  text-align: center;
  line-height: 39px;
  font-size: 14px;
  color: white;
}
// main
.main {
  font-family: PingFangTC-Regular, "PingFang TC", sans-serif;
  max-width: 1280px;
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  .title {
    color: #3a6ba5;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 30px;
    font-size: 30px;
    font-weight: 400;
    font-family: "PingFangTC-Regular", "PingFang TC", sans-serif;
  }
  .remind {
    color: rgb(237, 85, 4);
    width: 300px;
    font-weight: 400;
    margin: 0 auto;
    p {
      margin-bottom: 10px;
    }
  }
  .ver-input {
    &::placeholder {
      text-align: center;
    }
    text-indent: 10px;
    width: 80%;
    height: 45px;
    border-radius: 5px;
    border-width: 2px;
    border: 1px solid #ccc;
    margin: 20px auto;
    display: flex;
    justify-content: center;
  }
  .timing {
    color: rgb(237, 85, 4);
    width: 300px;
    margin: 70px auto;
    height: 30px;
    line-height: 30px;
    .count {
      font-size: 30px;
      margin-top: 15px;
      display: flex;
      justify-content: center;
      .time-text {
        font-size: 40px;
        margin-right: 5px;
      }
    }
  }
  .next-step {
    width: 250px;
    margin: 40px auto;
    height: 60px;
    background-color: rgba(249, 159, 0, 1);
    border-radius: 50px;
    text-align: center;
    line-height: 60px;
    color: white;
    font-weight: bold;
  }
  .disable {
    width: 250px;
    margin: 40px auto;
    height: 60px;
    border-radius: 50px;
    text-align: center;
    line-height: 60px;
    color: white;
    font-weight: bold;
    background-color: #ccc;
  }
}
.button-group {
  padding-bottom: 15px;
}
.success {
  color: #28a745;
  font-size: 40px;
}
</style>
