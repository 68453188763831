import axios from 'axios'
import store from '../store/index'
axios.defaults.baseURL = process.env.VUE_APP_baseURL
axios.interceptors.request.use((config) => {
  config.withCredentials = true
  store.commit('holdLoading', true)
  return config
})
axios.interceptors.response.use((config) => {
  config.withCredentials = true
  store.commit('holdLoading', false)
  return config
}, e => {
  store.commit('holdLoading', false)
  store.commit('showErrorMessage', e.response.data.message)
  return Promise.reject(e)
})
export default axios
