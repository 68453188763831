<template>
  <el-dialog v-model="visible" width="75%" :show-close="false">
    <div>
      <div class="dialog-wrap">
        <slot name="icon">
          <font-awesome-icon class="warning" icon="exclamation-circle"/>
        </slot>
        <p class="msg">{{ message }}</p>
      </div>
    </div>
    <slot name="footer">
      <div
        class="close"
        :style="{color:loginStyle.buttons.textColor,backgroundColor: loginStyle.buttons.backgroundColor}"
        @click="nextStep"
      >確認
      </div>
    </slot>
  </el-dialog>
</template>
<script>
import { defineComponent, watch, ref, toRefs } from 'vue'

export default defineComponent({
  name: 'Dialog',
  emits: ['nextStep'],
  props: {
    loginStyle: {
      type: Object,
      default: () => {
        return {}
      }
    },
    dialogVisible: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    message: {
      type: String,
      default: () => {
        return '出現錯誤，請稍候嘗試'
      }
    }
  },
  setup(props, context) {
    const { dialogVisible } = toRefs(props)
    const visible = ref(false)
    // 監聽 prop再賦值給此元件的值 因 vue不允許 用emit修改prop父層之值 （單向資料流
    watch(
      dialogVisible,
      newValue => {
        visible.value = newValue
      },
      { immediate: true }
    )
    const nextStep = () => {
      context.emit('nextStep')
    }
    return {
      nextStep,
      visible
    }
  }
})
</script>
<style lang="scss" scoped>
.dialog-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  .warning {
    font-size: 40px;
  }
  .msg {
    text-align: center;
  }
}
.close {
  margin: 15px auto;
  width: 150px;
  height: 39px;
  background-color: rgba(249, 159, 0, 1);
  border-radius: 50px;
  text-align: center;
  line-height: 39px;
  font-size: 14px;
  color: white;
}
</style>
