import { createStore } from 'vuex'
import axios from '../axios/index'

const getData = async(url, method = 'GET', params = {}) => {
  const { data } = await axios({
    url,
    method,
    params
  })
  return data
}
const store = createStore({
  state: {
    isLoading: false,
    dialogShow: false,
    isLoging: false,
    errorMessage: '頁面錯誤,請關閉重試',
    memberCenter: '',
    memberRegister: '',
    userPhone: '',
    userInformation: '',
    memberLogin: ''
  },
  mutations: {
    holdLoading(state, loading) {
      state.isLoading = loading
    },
    showErrorMessage(state, msg) {
      state.errorMessage = msg
    },
    setDialogShow(state, isShow) {
      state.dialogShow = isShow
    },
    setMemberCenter(state, setting) {
      state.memberCenter = setting
      state.userPhone = setting.memberPhone
    },
    setMemberRegister(state, setting) {
      state.memberRegister = setting
    },
    setUserPhone(state, phone) {
      state.userPhone = phone
    },
    SetUserInformation(state, info) {
      state.userInformation = info
    },
    setMemberLogin(state, setting) {
      state.memberLogin = setting
    }
  },
  actions: {
    checkLogin({ commit }, userInfo) {
      const { token, linebotGuid } = userInfo
      const params = { token }
      return getData(`/api/LINE/${linebotGuid}/MemberCenter/checkLogin`, 'GET', params)
    }
  },
  modules: {}
})
export default store
