<template>
  <div class="main" :style="{backgroundColor: style.backgroundColor}">
    <div class="title" :style="{color: style.titleColor}">申請LINE個人化服務</div>
    <div class="text-wrap">
      <div class="paragraph">申請LINE官方帳號綁定服務時，將根據您提供的行動電話與LINE帳號進行簡訊身份驗證。</div>
      <br/>
      <div>成功綁定個人化服務後，若更換手機或重新安裝LINE APP，需以原本LINE ID登入，以利後續LINE個人化服務使用。</div>
    </div>
    <div class="comfirm">
      <input v-model="checked" id="checked" type="checkbox"/>
      <label for="checked" :style="{color: style.textColor}">我已詳閱並同意相關使用須知</label>
    </div>
    <div
      @click="nextStep"
      v-if="checked"
      :style="{backgroundColor: style.buttons.backgroundColor,color: style.buttons.textColor}"
      class="next-step"
    >確認
    </div>
    <div v-else class="next-step" :class="{disable:!checked}">確認</div>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'Home',
  components: {},
  setup() {
    const store = useStore()
    const router = useRouter()
    const checked = ref()
    const memberLogin = computed(() => {
      return store.state.memberLogin
    })
    const { style } = memberLogin.value
    const nextStep = () => {
      router.push({ name: 'login' })
    }
    return {
      checked,
      nextStep,
      style
    }
  }
})
</script>
<style lang="scss" scoped>
.main {
  min-height: 100%;
}
.title {
  color: #3a6ba5;
  font-size: 30px;
  font-weight: 400;
  padding-top: 50px;
  padding-bottom: 30px;
  text-align: center;
  font-family: "PingFangTC-Regular", "PingFang TC", sans-serif;
}
.text-wrap {
  .paragraph {
    padding-top: 50px;
    font-size: 16px;
  }
  width: 70%;
  height: 250px;
  padding: 15px;
  margin: 0px auto;
  background-color: white;
  border-radius: 5px;
}
.comfirm {
  margin: 30px auto;
  width: 250px;
  align-items: center;
  input {
    margin-right: 20px;
  }
  label {
    color: #ed5504;
  }
}
.next-step {
  width: 250px;
  margin: 20px auto;
  height: 60px;
  background-color: rgba(249, 159, 0, 1);
  border-radius: 50px;
  text-align: center;
  line-height: 60px;
  color: white;
  font-weight: bold;
}
.disable {
  width: 250px;
  margin: 20px auto;
  height: 60px;
  border-radius: 50px;
  text-align: center;
  line-height: 60px;
  color: white;
  font-weight: bold;
  background-color: #ccc;
}
</style>
