import { createRouter, createWebHistory } from 'vue-router'
// createWebHashHistory
import checkLogin from '../views/checkLogin.vue'
import login from '../views/login.vue'
import verifyOtp from '../views/verifyOtp.vue'
import registered from '../views/registered.vue'
import privacy from '../views/privacy.vue'
import memberCenter from '../views/memberCenter.vue'
import edit from '../views/edit.vue'
import errorPage from '../views/errorPage.vue'
import store from '../store/index'

const routes = [
  {
    path: '/',
    name: 'checkLogin',
    component: checkLogin
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: privacy
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/verifyOtp',
    name: 'verifyOtp',
    component: verifyOtp
  },
  {
    path: '/registered',
    name: 'registered',
    component: registered
  }, {
    path: '/memberCenter',
    name: 'memberCenter',
    component: memberCenter
  },
  {
    path: '/edit',
    name: 'edit',
    component: edit
  },
  {
    path: '/error',
    name: 'errorPage',
    component: errorPage,
    meta: { current: 'error' }
  }
  // { path: '*', component: errorPage }
]
const router = createRouter({
  // process.env.VUE_APP_baseURL
  history: createWebHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  const isMobile = /Android|webOS|iPhone|iPad|iOS|BlackBerry/i.test(
    navigator.userAgent)
  if (!isMobile) {
    store.commit('showErrorMessage', '此功能僅限手機使用')
    // 預防無限迴圈
    to.meta.current === 'error' ? next() : next({ name: 'errorPage' })
  } else {
    next()
  }
})
export default router
