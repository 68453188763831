import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
// {/* <i class="far fa-check-circle"></i> */}
import {
  faExclamationCircle,
  faCheckCircle,
  faSpinner,
  faMapMarkerAlt,
  faBook,
  faFolderOpen
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  ElDialog,
  ElButton,
  ElLoading,
  ElForm,
  ElFormItem,
  ElInput,
  ElSelect,
  ElOption,
  ElDatePicker,
  ElCard,
  ElCol,
  ElRow
} from 'element-plus'
import 'element-plus/packages/theme-chalk/src/base.scss'

const app = createApp(App)
// ... e => {
// const { error } = e.response.data
// 攔截到失敗也要關掉
//  endLoading()
// 把error設定給 message讓彈出框好使用
// store.commit('setError', { status: true, message: error })
//  return Promise.reject(error)
// }
// )

const components = [
  ElDialog,
  ElButton,
  ElLoading,
  ElForm,
  ElFormItem,
  ElInput,
  ElSelect,
  ElOption,
  ElDatePicker,
  ElCard,
  ElCol,
  ElRow]
library.add(faExclamationCircle, faCheckCircle, faSpinner, faMapMarkerAlt,
  faBook, faFolderOpen)
app.component('font-awesome-icon', FontAwesomeIcon)
components.forEach(component => {
  app.component(component.name, component)
})
app.use(store).use(router).mount('#app')
