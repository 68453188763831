<template>
  <div class="member-container">
    <div class="main-wrap" :style="{backgroundColor: style.backgroundColor}">
      <div class="title" :style="{color: style.titleColor}">{{ memberTitle }}</div>
      <el-card class="member-card">
        <div class="roles_wrap">
          <div
            :style="{backgroundColor: style.buttons.backgroundColor,color:style.buttons.textColor}"
            class="card-rows"
            v-for="roles in memberCard.roles"
            :key="roles"
          >{{ roles }}
          </div>
        </div>
        <div class="barcode-wrap">
          <svg class="barcode-img" id="barcode"/>
        </div>
      </el-card>
      <el-card class="card-info">
        <div class="card-info-row">
          <el-row class="info_row">
            <el-col :span="6">綁定手機</el-col>
            <el-col :span="18" style="word-wrap:break-word">{{ memberPhone }}</el-col>
          </el-row>
        </div>
        <div class="card-info-row">
          <el-row class="info_row">
            <el-col :span="6">會員卡號</el-col>
            <el-col :span="18" style="word-wrap:break-word">{{ memberCard.cardId }}</el-col>
          </el-row>
        </div>
        <div class="card-info-header">
          <div>個人資料</div>
          <router-link to="/edit">
            <i v-if="memberInfo.canEdit" class="el-icon-edit-outline"></i>
          </router-link>
        </div>
        <div class="card-info-row" v-for="info in memberInfo.info" :key="info.title">
          <el-row class="info_row">
            <el-col class="info-title" :span="6">{{ info.title }}</el-col>
            <el-col :span="18" style="word-wrap:break-word">{{ info.value }}</el-col>
          </el-row>
        </div>
      </el-card>
    </div>
    <div class="icon-wrap">
      <div
        @click="linkurl(item.uri)"
        class="icon-botton"
        v-for=" item in buttons"
        :key=" getUID(item)"
      >
        <span class="button">
          <div
            :style="{color: style.memberCenter.buttons.textColor}"
            class="icon-title"
          >{{ item.title }}</div>
          <img class="icon" :src="item.icon"/>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted } from 'vue'
import { useStore } from 'vuex'

const JsBarcode = require('jsbarcode')
let uid = 0
const Item2UIDMap = new WeakMap()
export default defineComponent({
  setup(props) {
    const store = useStore()
    const memberSetting = store.state.memberCenter
    const {
      centerStyle,
      style,
      buttons,
      memberCard,
      memberInfo,
      editQuestions,
      memberPhone,
      memberTitle
    } = memberSetting
    const codeType = memberCard.type[0]
    onMounted(() => {
      if (codeType === 'barcode') {
        const code = memberCard.cardId
        JsBarcode('#barcode', code, {
          width: 3,
          height: 70,
          fontSize: 18
        })
      }
    })
    const linkurl = (uri) => {
      location.href = uri
    }
    const getUID = (item) => {
      const persistedUID = Item2UIDMap.get(item)
      if (!persistedUID) {
        Item2UIDMap.set(item, ++uid)
        return uid
      }
      return persistedUID
    }
    const OpenIconUrl = (url) => {
      window.location.href = url
    }
    memberInfo.info.map(item => {
      if (item.title === '性別') {
        switch (item.value) {
          case 'F':
            item.value = '女'
            break
          case 'M':
            item.value = '男'
            break
        }
      }
    })
    return {
      buttons,
      centerStyle,
      OpenIconUrl,
      memberCard,
      memberInfo,
      memberPhone,
      memberTitle,
      editQuestions,
      getUID,
      linkurl,
      style
    }
  }
})
</script>
<style lang="scss" scoped>
.member-container {
  height: 100%;
  .main-wrap {
    overflow-y: auto;
    display: flex;
    height: calc(100% - 80px);
    flex-direction: column;
    .title {
      color: #3a6ba5;
      font-size: 20px;
      text-align: center;
      padding-top: 20px;
      margin: 0px auto;
    }
    .member-card {
      width: 90%;
      border-radius: 15px;
      margin: 15px auto;
      .roles_wrap {
        margin-bottom: 10px;
        display: flex;
        flex-wrap:wrap ;
        padding-right: 3px;
        padding-left: 5px;
        .card-rows {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 5px;
          margin-top: 5px;
          padding: 0px 2px;
          font-size: 14px;
          min-width: 36px;
          font-weight: bold;
          height: 25px;
          border-radius: 3px;
        }
      }
      .barcode-img {
        width: 100%;
        height: auto;
      }
    }
    .card-info {
      flex: 1;
      border-radius: 15px 15px 0px 0px;
      .card-info-row {
        line-height: 18px;
        margin-bottom: 80px;
        .info-title {
          padding-right: 5px;
          color: rgb(173, 173, 173);
        }
        margin-bottom: 20px;
      }
      .card-info-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        i {
          font-size: 25px;
        }
      }
    }
  }
  .icon-wrap {
    width: 100%;
    height: 80px;
    background-color: white;
    display: flex;
    .icon-botton {
      cursor: pointer;
      display: flex;
      padding: 10px 0px;
      flex: 1;
      justify-content: center;
      align-items: center;
      position: relative;
      &:last-child::after {
        border-right: none;
      }
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-top: 1px solid rgb(215, 215, 215);
        border-right: 1px solid rgb(215, 215, 215);
        left: 0;
        top: 0;
      }
    }
    .icon {
      width: 40px;
      vertical-align: bottom;
      height: 40px;
    }
    .button {
      display: flex;
    }
    .icon-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      word-break: break-all;
      min-width: 32px;
      max-width: 32px;
      font-size: 14px;
      text-align: center;
      padding-right: 2px;
    }
  }
}
::v-deep .el-card {
  overflow: initial;
}
</style>
