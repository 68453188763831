<template>
  <div></div>
  <customer-dialog
    :loginStyle="loginStyle"
    :dialogVisible="visible"
    @nextStep="closeDialog"
    :message="errorMsg"
  ></customer-dialog>
</template>
<script>
import { defineComponent, computed, ref } from 'vue'
import { useStore } from 'vuex'
import liff from '@line/liff'
import customerDialog from '../components/customerDialog'

export default defineComponent({
  components: {
    customerDialog
  },
  setup() {
    const store = useStore()
    const visible = ref(true)
    const errorMsg = computed(() => {
      return store.state.errorMessage
    })
    //     margin: 15px auto;
    // width: 150px;
    // height: 39px;
    // background-color: rgba(249, 159, 0, 1);
    // border-radius: 50px;
    // text-align: center;
    // line-height: 39px;
    // font-size: 14px;
    // color: white;
    const loginStyle = {
      buttons: {
        backgroundColor: '#ffa200',
        textColor: '#ffffff'
      }
    }
    const closeDialog = () => {
      visible.value = false
      liff.closeWindow()
    }
    return {
      visible,
      errorMsg,
      closeDialog,
      loginStyle
    }
  }
})
</script>
