<template>
  <div class="container">
    <customer-dialog
      :loginStyle="style"
      :dialogVisible="dialogVisible"
      @nextStep="nextStep"
      :message="resMsg"
    >
      <template
        #icon
        v-if="resMsg==='編輯成功'"
      >
        <font-awesome-icon
          class="success"
          icon="check-circle"
        />
      </template>
    </customer-dialog>
    <member-form
      :style="style"
      :isEdit="true"
      @sendEdit="sendData"
      :registQuestion="editQuestion"
    ></member-form>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue'
import memberForm from '../components/memberForm.vue'
import customerDialog from '../components/customerDialog'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import axios from '../axios/index'

export default defineComponent({
  components: { memberForm, customerDialog },
  setup() {
    const router = useRouter()
    const store = useStore()
    const editQuestion = store.state.memberCenter.editQuestions
    const params = store.state.userInformation
    const userInfo = params || JSON.parse(sessionStorage.getItem('userInfo'))
    const { style } = store.state.memberCenter
    const resMsg = ref('編輯成功')
    const dialogVisible = ref(false)
    const nextStep = () => {
      dialogVisible.value = false
      router.push({ name: 'memberCenter' })
    }
    const sendData = (emit) => {
      axios({
        method: 'PUT',
        url: `/api/LINE/${userInfo.linebotGuid}/MemberCenter/update`,
        data: emit
      }).then(res => {
        store.commit('setMemberCenter', res.data.data.memberCenter)
        resMsg.value = '編輯成功'
        dialogVisible.value = true
      }).catch(() => {
        store.commit('showErrorMessage', '編輯失敗，請和客服聯繫')
        router.push({ name: 'errorPage' })
      })
    }
    return {
      editQuestion,
      sendData,
      dialogVisible,
      resMsg,
      nextStep,
      style
    }
  }
})
</script>
<style lang="scss" scoped>
.dialog-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  .warning {
    font-size: 40px;
  }
  .msg {
    text-align: center;
  }
}
.close {
  margin: 15px auto;
  width: 150px;
  height: 39px;
  background-color: rgba(249, 159, 0, 1);
  border-radius: 50px;
  text-align: center;
  line-height: 39px;
  font-size: 14px;
  color: white;
}
.container {
  height: 100%;
  padding-bottom: -40px;
}
.success {
  color: #28a745;
  font-size: 40px;
}
</style>
