<template>
  <div class="outer" :style="{backgroundColor:loginStyle.backgroundColor}">
    <div :style="{color:loginStyle.titleColor}" class="title" v-if="currentPage!=='edit'">填寫會員資料</div>
    <div :style="{color:loginStyle.titleColor}" class="title" v-else>編輯會員資料</div>
    <el-card class="main">
      <div class="account">
        <div class="account-text" :style="{color:loginStyle.textColor}">綁定手機號碼</div>
        <div class="tel">{{ tel }}</div>
      </div>
      <el-form @submit.prevent class="regist" ref="ruleForm" :model="registAnswer" :rules="rules">
        <div v-for="question in registQuestion" :key="question.input.key">
          <el-form-item v-if="question.type!=='member'" :prop="question.input.key">
            <div class="el-row" :style="{color:loginStyle.textColor}">
              {{ question.input.title }}
              <div
                class="require"
                :style="{color:loginStyle.textColor}"
                v-if="question.input.required===true"
              >＊
              </div>
            </div>
            <el-input
              v-if="question.input.type!=='select'"
              v-model="registAnswer[question.input.key]"
              :placeholder="`請填寫${question.input.title}`"
              class="input"
              :type="question.input.type"
            ></el-input>
            <el-select
              v-if="question.input.type=='select'"
              v-model="registAnswer[question.input.key]"
              :placeholder="`請填寫${question.input.title}`"
              class="input"
              :type="question.input.type"
            >
              <el-option
                :label="option.label"
                :value="option.value"
                :key="option.value"
                v-for="option in question.input.options"
              ></el-option>
            </el-select>
            <div class="remind" v-if="!question.canEdit &&currentPage!=='edit'">送出後不可更改</div>
          </el-form-item>
        </div>
      </el-form>
      <div class="footer">
        <!-- 註冊的按鈕 -->
        <div
          class="next-step"
          :style="{color:loginStyle.buttons.textColor,backgroundColor: loginStyle.buttons.backgroundColor}"
          v-if="!isEdit"
          @click="sendRegistAnswer()"
        >確認
        </div>
        <!-- 編輯的按鈕 -->
        <div
          class="next-step"
          :style="{color:loginStyle.buttons.textColor,backgroundColor:loginStyle.buttons.backgroundColor}"
          v-if="isEdit"
          @click="sendEditAnswer()"
        >確認
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import { defineComponent, computed, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

export default defineComponent({
  emits: ['sendRegist', 'sendEdit'],
  props: {
    style: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    registQuestion: {
      type: Array,
      default: () => {
        return [{}]
      }
    }
  },
  setup(props, context) {
    const store = useStore()
    const dialogVisible = ref(false)
    const route = useRoute()
    const ruleForm = ref()
    const loginStyle = ref(props.style)
    const params = store.state.userInformation
    const userInfo = params || JSON.parse(sessionStorage.getItem('userInfo'))
    const tel = store.state.userPhone
    const currentPage = route.name
    // 根據type設置規則
    const rules = {}
    // user填寫的答案 v-modle 綁定的值
    const registAnswer = reactive({})
    // 要送出的資料 包含 type key value
    const sendAnswer = reactive([])
    // axios最後要送出的值
    const userAnswer = {
      token: userInfo.token,
      phone: tel,
      questions: sendAnswer
    }
    // api撈出的註冊問題
    props.registQuestion.map((question) => {
      const keyName = question.input.key
      registAnswer[keyName] = question.input.value
      sendAnswer.push({
        key: keyName,
        type: question.type,
        value: computed(() => {
          return registAnswer[keyName] ? registAnswer[keyName] : ''
        })
      })
      if (question.input.required) {
        // 動態添加規則 必填
        rules[keyName] = [{ required: true, message: `${question.input.title}為必填欄位`, trigger: 'blur' }]
      }
      switch (keyName) {
        // 動態添加規則
        case 'phone':
          rules[question.input.key].push({ pattern: /^09[0-9]{8}$/, message: '請填寫正確電話號碼' })
          break
        case 'email':
          if (rules.email !== undefined) {
            rules[question.input.key].push({ type: 'email', message: '請填寫正確電子郵件' })
          } else {
            rules.email = []
            rules[question.input.key].push({ type: 'email', message: '請填寫正確電子郵件' })
          }
          break
      }
    })
    // 編輯
    const sendEditAnswer = () => {
      ruleForm.value.validate((pass) => {
        if (pass) {
          // edit 不需要phone / token
          const data = {
            questions: sendAnswer
          }
          context.emit('sendEdit', data)
        }
      })
    }
    const sendRegistAnswer = () => {
      ruleForm.value.validate((pass) => {
        if (pass) {
          context.emit('sendRegist', userAnswer)
        }
      })
    }
    return {
      tel,
      registAnswer,
      rules,
      sendRegistAnswer,
      ruleForm,
      currentPage,
      sendEditAnswer,
      dialogVisible,
      loginStyle
    }
  }
})
</script>
<style lang="scss" scoped>
.main {
  width: 88%;
  margin: 0px auto 30px auto;
  flex: auto;
  overflow-y: scroll;
  position: relative;
}
.account {
  .account-text {
    color: #ed5504;
    font-size: 17px;
    margin-bottom: 10px;
  }
  margin-bottom: 10px;
}
.title {
  text-align: center;
  color: rgb(58, 107, 165);
  font-weight: 400;
  font-size: 30px;
  padding-top: 50px;
  padding-bottom: 30px;
}
.decoration {
  width: 113px;
  color: white;
  line-height: 30px;
  text-align: center;
  height: 30px;
  border-radius: 0px 50px 50px 0px;
  background-color: rgb(249, 159, 0);
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 15px;
  margin-left: -20px;
}
.next-step {
  margin: 20px auto;
  width: 150px;
  height: 39px;
  background-color: rgba(249, 159, 0, 1);
  border-radius: 50px;
  text-align: center;
  line-height: 39px;
  font-size: 14px;
  color: white;
}
.select-item {
  display: flex;
  flex-direction: column;
}
.input {
  width: 100%;
}
.remind {
  text-align: right;
  color: rgb(173, 170, 173);
  margin-bottom: -23px;
}
.outer {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.el-form-item::v-deep(.el-form-item__label) {
  text-align: left;
}
.el-form-item::v-deep(.el-date-editor) {
  width: 100%;
}
:deep(.el-card__body) {
  padding: 20px 20px 69px 20px;
  height: calc(100% - 122px);
}
.footer {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.regist {
  min-height: calc(100% - 122px);
}
.canedit {
  position: absolute;
  right: 0;
  color: #aaaaaa;
}
.require {
  color: red;
}
.el-row {
  display: flex;
  align-items: center;
}
</style>
